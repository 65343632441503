<template>
  <v-dialog
    v-model="dialog"
    width="800"
    scrollable
    :persistent="loading"
    :disabled="loading || nugget.is_accepted || nugget.is_deleted"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @click:outside="loading ? null : closeDialog()"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="
              hover && !nugget.is_accepted && !nugget.is_deleted ? 4 : 0
            "
            outlined
            style="transition: 0.4s"
            :disabled="loading"
          >
            <v-overlay
              v-if="nugget.is_accepted || nugget.is_deleted"
              absolute
              color="secondary"
              opacity=".8"
            >
              <div class="text-center">
                <h3>
                  {{
                    nugget.is_accepted
                      ? $t("general.accepted")
                      : $t("general.rejected")
                  }}
                </h3>
                <v-btn
                  small
                  outlined
                  :disabled="loading || disabled"
                  @click="nugget.is_accepted ? acceptNugget() : deleteNugget()"
                >
                  <span>{{ $t("general.undo") }}</span>
                  <v-icon small class="ml-2">mdi-replay</v-icon>
                </v-btn>
              </div>
            </v-overlay>
            <div class="d-flex align-center pt-2 px-4">
              <v-icon small>mdi-school</v-icon>
              <span class="overline ml-2">{{
                $t("resources.nuggets.singular")
              }}</span>
              <v-spacer />
              <!-- <v-icon small>mdi-arrow-expand</v-icon> -->
              <v-slide-x-transition hide-on-leave>
                <div
                  v-show="hover && !(nugget.is_accepted || nugget.is_deleted)"
                >
                  <span class="caption mr-2">{{
                    $t("general.click_to_edit")
                  }}</span>
                  <v-icon small>mdi-pencil</v-icon>
                </div>
              </v-slide-x-transition>
              <v-slide-x-transition hide-on-leave>
                <div
                  v-show="!hover && !nugget.is_accepted && !nugget.is_deleted"
                >
                  <v-icon small>mdi-arrow-expand</v-icon>
                </div>
              </v-slide-x-transition>
            </div>
            <v-card-subtitle class="pt-0">{{ nugget.bundle }}</v-card-subtitle>

            <v-card-text v-if="bundleImages && bundleImages.length > 0">
              <GwNuggetSlideSelector
                :images="bundleImages"
                :nugget-id="nugget.id"
                :journey-id="journeyId"
                :selected="nugget.image"
                @loading="setLoading"
              />
            </v-card-text>

            <v-card-title class="pt-1" style="word-break: break-word">{{
              nugget.title
            }}</v-card-title>
            <v-card-subtitle>{{ nugget.description }}</v-card-subtitle>
            <v-card-text v-show="!nugget.is_accepted && !nugget.is_deleted">{{
              nugget.content
            }}</v-card-text>
            <v-divider />
            <v-card-actions class="pa-4">
              <v-btn
                small
                :text="nugget.is_deleted"
                :color="nugget.is_deleted ? 'white' : 'error'"
                :disabled="loading || disabled"
                @click.stop="deleteNugget()"
              >
                <span>{{ $t("resources.questions.review.reject") }}</span>
                <v-icon small class="ml-2">mdi-close-circle</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                small
                color="success"
                :disabled="loading || disabled"
                @click.stop="acceptNugget()"
              >
                <span>{{ $t("resources.questions.review.accept") }}</span>
                <v-icon small class="ml-2">mdi-check-circle</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </div>
    </template>

    <v-card v-if="dialog" color="grey lighten-3">
      <v-card-title class="overline secondary--text">{{
        $t("resources.nuggets.edit_nugget")
      }}</v-card-title>
      <v-card-subtitle>{{ nugget.bundle }}</v-card-subtitle>
      <v-card-text>
        <GwNuggetSlideSelector
          v-if="bundleImages && bundleImages.length > 0"
          :images="bundleImages"
          :nugget-id="nugget.id"
          :journey-id="journeyId"
          :selected="nugget.image"
          bg-color="grey lighten-2"
          class="mb-4"
          @loading="setLoading"
        />

        <v-form v-model="valid">
          <v-row dense>
            <v-col cols="12" class="pt-2">
              <v-text-field
                v-model="edited_nugget.title"
                :label="$t('resources.nuggets.title')"
                outlined
                background-color="white"
                dense
                :disabled="disabled"
                :rules="rules.title"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="edited_nugget.description"
                :label="$t('resources.nuggets.description')"
                outlined
                background-color="white"
                dense
                height="80"
                :disabled="disabled"
                :rules="rules.description"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="edited_nugget.content"
                :label="$t('resources.nuggets.content')"
                outlined
                background-color="white"
                dense
                height="300"
                :disabled="disabled"
                :rules="rules.content"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn small text :disabled="loading" @click="closeDialog()">{{
          $t("general.close")
        }}</v-btn>
        <v-spacer />
        <v-btn
          small
          color="success"
          depressed
          text
          :disabled="!nuggetHasChanged || loading || disabled || !valid"
          @click="updateNugget()"
          >{{ $t("general.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import GwNuggetSlideSelector from "@/components/files/GwNuggetSlideSelector";

export default {
  name: "GwJourneyNuggetCard",
  components: { GwNuggetSlideSelector },
  props: {
    nugget: {
      type: Object,
      required: true,
    },
    journeyId: {
      type: [String, Number],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    bundleImages: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      edited_nugget: null,
      loading: false,
      rules: {
        title: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("ng.too_many_chars_hint", {
              count: v.length,
              max: 255,
            }),
        ],
        description: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("ng.too_many_chars_hint", {
              count: v && v.length,
              max: 255,
            }),
        ],
        content: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          //(v) => !!v && v.length <= 64 || this.$t('ng.too_many_chars_hint', { count: v.length, max: 64 })
        ],
      },
      valid: false,
      currentImage: null,
    };
  },
  computed: {
    nuggetHasChanged() {
      var changed = false;
      if (
        this.nugget.title !== this.edited_nugget.title ||
        this.nugget.description !== this.edited_nugget.description ||
        this.nugget.content !== this.edited_nugget.content
      )
        changed = true;
      return changed;
    },
  },
  watch: {
    dialog(v) {
      if (v)
        this.edited_nugget = JSON.parse(JSON.stringify({ ...this.nugget }));
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "update_gw_journey_nugget",
      "reject_gw_journey_nugget",
    ]),
    closeDialog() {
      this.dialog = false;
    },
    async acceptNugget() {
      this.loading = true;
      var payload = {
        is_accepted: !this.nugget.is_accepted,
      };
      var res = await this.update_gw_journey_nugget({
        payload: payload,
        id: this.nugget.id,
        journey_id: this.journeyId,
      });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    async deleteNugget() {
      this.loading = true;
      var res = await this.reject_gw_journey_nugget({
        action: this.nugget.is_deleted ? "unreject" : "reject",
        id: this.nugget.id,
        journey_id: this.journeyId,
      });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    async updateNugget() {
      this.loading = true;
      var payload = {
        title: this.edited_nugget.title,
        description: this.edited_nugget.description,
        content: this.edited_nugget.content,
      };
      var res = await this.update_gw_journey_nugget({
        payload: payload,
        id: this.nugget.id,
        journey_id: this.journeyId,
      });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    setLoading($e) {
      this.loading = $e;
    },
  },
};
</script>
