<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="
        journey ? journey.name : $t('resources.journeys.journey.singular')
      "
      icon="mdi-comment-question"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          color="grey lighten-2"
          :to="{ name: 'JourneyCurrent' }"
          exact
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>

      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>
    </ViewHeader>

    <ViewLoader v-if="!journey" />

    <router-view v-if="journey" :key="$route.fullPath" class="mt-4" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
/* import JourneyNav from "@/components/journeys/JourneyNav.vue"; */
import ViewNavigation from "@/components/_layout/ViewNavigation";

export default {
  name: "JourneyDetail",
  components: {
    ViewHeader,
    ViewLoader,
    /* JourneyNav, */
    ViewNavigation,
  },
  data() {
    return {
      loading: true,
      id: 0,
      tab: null,
      showDeleteDialog: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      language: "get_selected_language",
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.journeys.title"),
          disabled: false,
          to: { name: "JourneyCurrent" },
        },
      ];
    },
    navItems() {
      var items = [
        {
          title: this.$t("resources.journeys.navItems.overview"),
          link: { name: "JourneySummary" },
        },
        {
          title: this.$t("resources.journeys.navItems.contents"),
          link: { name: "JourneyBundles" },
        },
        {
          title: this.$t("resources.journeys.navItems.settings"),
          link: { name: "JourneySettings" },
        },
        {
          title: this.$t("resources.journeys.navItems.visibility"),
          link: { name: "JourneyStructures" },
        },
        {
          title: this.$t("certificate.singular"),
          link: { name: "JourneyCertificate" },
        },
        {
          title: this.$t("resources.journeys.navItems.evaluation"),
          link: { name: "JourneyStatistics" },
          disabled:
            this.journey &&
            ["unpublished", "published"].includes(this.journey.status),
        },
      ];
      return items;
    },
  },
  watch: {
    language: {
      handler: function () {
        this.fetchData();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager", "editor"]);
    this.id = Number(this.$route.params.id);
  },
  destroyed() {
    this.set_journey(null);
    this.set_structures(null);
    this.set_substructures(null);
    this.set_selected_language(this.$i18n.locale);
  },
  methods: {
    ...mapActions("journeys", ["fetch_journey"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("journeys", ["set_journey", "set_selected_language"]),
    ...mapMutations("structures", ["set_structures", "set_substructures"]),
    async fetchData() {
      this.loading = true;
      if (this.id) {
        let res = await this.fetch_journey({
          id: this.id,
          language: this.language,
        });
        if (res && res._status === 403) this.$router.push("/error/403");
        await this.fetch_structures();
      }
      this.loading = false;
    },
    closeDeleteDialog(wasDeleted) {
      this.showDeleteDialog = false;
      if (wasDeleted) this.$router.push({ name: "JourneyCurrent" });
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
  },
};
</script>

<style></style>
