<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog v-model="showDialog" max-width="640" persistent scrollable>
      <v-form v-model="valid">
        <v-card
          v-if="showDialog"
          color="grey lighten-3"
          max-width="640"
          :loading="loading"
        >
          <v-card-title class="overline secondary--text">
            <span>{{ $t("gw.journey.generate.title") }}</span>
          </v-card-title>
          <v-card-subtitle
            v-if="!generating_success && !is_generating"
            class="pb-0"
          >
            <p>
              {{ $t("gw.journey.generate.p1") }}
            </p>
            <p class="mb-0">
              {{ $t("gw.journey.generate.p2") }}
            </p>
          </v-card-subtitle>
          <v-card-text>
            <div v-if="!source_job && !generating_success" class="py-2">
              <v-chip-group
                v-model="inputMode"
                mandatory
                active-class="primary"
              >
                <v-chip small>{{
                  $t("gw.journey.generate.chips.text")
                }}</v-chip>
                <v-chip small :disabled="hidePrevSources">{{
                  $t("gw.journey.generate.chips.pdf")
                }}</v-chip>
                <v-chip v-show="false" small :disabled="true">{{
                  $t("gw.journey.generate.chips.video")
                }}</v-chip>
                <v-chip
                  v-if="showPPTFeature"
                  small
                  :disabled="hidePrevSources"
                  >{{ $t("gw.journey.generate.chips.ppt") }}</v-chip
                >
              </v-chip-group>
            </div>

            <div
              v-if="!source_job && !generating_success && !hidePrevSources"
              class="pa-0"
            >
              <v-sheet
                color="grey lighten-2"
                class="pt-2 pb-4 px-4"
                style="border: 1px solid rgba(0, 0, 0, 0.13) !important"
              >
                <v-row dense>
                  <v-col cols="12">
                    <div class="pt-1 pb-2">
                      {{ $t("ng.use_prev_source") }}
                    </div>
                    <v-autocomplete
                      v-if="[0, 1].includes(inputMode)"
                      v-model="selected_source"
                      :items="filteredSources"
                      outlined
                      dense
                      item-text="title"
                      return-object
                      hide-details
                      clearable
                      :label="$t('general.please_select')"
                      background-color="white"
                      :disabled="loading"
                    >
                      <template #item="{ item, on, attrs }">
                        <v-list-item dense v-bind="attrs" v-on="on">
                          <v-list-item-avatar>
                            <country-flag
                              :country="flagName(item.lang)"
                              size="small"
                            />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.mimetype
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template #selection="{ item }">
                        <div class="d-flex align-center">
                          <country-flag
                            :country="flagName(item.lang)"
                            size="small"
                            class="mr-2"
                          />
                          <span>{{ item.title }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete
                      v-if="[3].includes(inputMode)"
                      v-model="selected_source"
                      :items="filteredFileSources"
                      outlined
                      dense
                      hide-details
                      item-text="title"
                      return-object
                      clearable
                      :label="$t('gw.presentation.select_existent')"
                      background-color="white"
                      :disabled="loading && true"
                    >
                      <template #item="{ item, on, attrs }">
                        <v-list-item dense v-bind="attrs" v-on="on">
                          <v-list-item-avatar>
                            <country-flag
                              :country="flagName(item.lang)"
                              size="small"
                            />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template #selection="{ item }">
                        <div class="d-flex align-center">
                          <country-flag
                            :country="flagName(item.lang)"
                            size="small"
                            class="mr-2"
                          />
                          <span>{{ item.title }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-sheet>
            </div>

            <div v-if="!source_job && !generating_success" class="mt-4">
              <v-row dense>
                <v-col v-if="!source && !hidePrevSources" cols="12">
                  <div class="pa-1">
                    {{
                      inputMode === 0
                        ? $t("ng.add_new_input")
                        : $t("ng.add_new_document")
                    }}
                  </div>
                  <v-file-input
                    v-if="inputMode === 1"
                    v-model="selectedFile"
                    ref="gwdocument"
                    accept=".pdf"
                    :label="$t('resources.invitations.fileInput')"
                    outlined
                    :background-color="loading ? 'grey lighten-3' : 'white'"
                    :disabled="loading"
                    small-chips
                    dense
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    :rules="rules.file"
                    clear-icon="mdi-close-circle"
                  />
                  <PresentationUploadDialog
                    v-if="inputMode === 3 && !source"
                    @filesource="setSource"
                  >
                    <template #action="{ openDialog }">
                      <v-file-input
                        :label="$t('resources.invitations.fileInput')"
                        outlined
                        :background-color="loading ? 'grey lighten-3' : 'white'"
                        :disabled="loading"
                        small-chips
                        dense
                        prepend-icon=""
                        prepend-inner-icon="mdi-paperclip"
                        :rules="rules.file"
                        clear-icon="mdi-close-circle"
                        @click.prevent="openDialog()"
                      >
                      </v-file-input>
                    </template>
                  </PresentationUploadDialog>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :value="title"
                    outlined
                    dense
                    :rules="rules.title"
                    persistent-hint
                    :hint="$t('gw.title_hint')"
                    :label="$t('resources.nuggets.title')"
                    :disabled="loading || !!source_job || !!source"
                    background-color="white"
                    @input="setTitle($event)"
                  >
                    <template v-if="!!source && false" #append>
                      <v-slide-x-transition>
                        <div v-show="title !== source.title">
                          <v-btn
                            small
                            icon
                            color="error"
                            style="margin-top: -3px"
                            :disabled="loading"
                            @click="title = source.title"
                          >
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                          <v-btn
                            small
                            icon
                            style="margin-top: -3px"
                            color="success"
                            :disabled="loading"
                            @click="changeSourceTitle()"
                          >
                            <v-icon small>mdi-check</v-icon>
                          </v-btn>
                        </div>
                      </v-slide-x-transition>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-if="inputMode !== 3 || (inputMode === 3 && !source)"
                    v-model="txt"
                    :label="$t('resources.nuggets.content')"
                    dense
                    outlined
                    :placeholder="
                      inputMode !== 0
                        ? $t('ng.filled_automatically')
                        : $t('ng.text_input')
                    "
                    :readonly="[1, 2, 3].includes(inputMode) || !!source"
                    background-color="white"
                    :rules="inputMode === 0 ? rules.text : []"
                    :disabled="loading"
                    :counter="showCharLengthWarning ? maxCharLength : false"
                  >
                    <template v-slot:counter="{ props }">
                      <v-counter v-bind="props" :value="txt.length"></v-counter>
                    </template>
                  </v-textarea>

                  <v-expand-transition>
                    <v-alert
                      v-if="showCharLengthWarning"
                      text
                      dense
                      type="error"
                    >
                      <div
                        class="caption"
                        v-html="
                          $t('ng.max_char_warning', {
                            max: maxCharLength.toLocaleString(),
                          })
                        "
                      ></div>
                      <v-row v-if="inputMode !== 0" align="center" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col class="shrink">
                          <v-btn
                            color="error"
                            small
                            text
                            @click="duplicateSource()"
                          >
                            Anpassen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-expand-transition>

                  <v-alert
                    v-if="inputMode === 3 && !!source"
                    :type="!source.body ? 'error' : 'info'"
                    text
                    dense
                    class="mt-2"
                  >
                    <span v-if="!source.body">{{
                      $t("gw.presentation.no_source_body")
                    }}</span>
                    <span v-if="!!source.body">
                      {{
                        $t("gw.presentation.source_body_hint", {
                          length: source.body.split(",").length,
                        })
                      }}
                    </span>
                    <FileSourceDialog
                      :source-id="selected_source ? selected_source.id : null"
                      close-on-change
                      @change="setSource"
                    >
                      <template #action="{ openDialog }">
                        <v-btn
                          color="secondary"
                          depressed
                          small
                          class="mt-2"
                          :disabled="!selected_source"
                          @click="openDialog()"
                        >
                          <v-icon left>mdi-presentation</v-icon>
                          <span>Folien bearbeiten</span>
                        </v-btn>
                      </template>
                    </FileSourceDialog>
                  </v-alert>
                </v-col>
              </v-row>

              <v-divider class="mt-2 mb-4" />

              <v-row dense>
                <v-col cols="12">
                  <LanguageChooserInline
                    colored
                    dense
                    class="pb-0"
                    hidedetails
                    :disabled="
                      loading || !!source || [3, 4].includes(inputMode)
                    "
                    :label="$t('general.language')"
                    :initial="source ? source.lang : lang"
                    @change="changeLanguage"
                  />
                  <v-alert
                    text
                    type="info"
                    color="info"
                    class="mt-4 mb-2 caption"
                    dense
                    >{{ $t("gw.bulk.hints.language") }}</v-alert
                  >
                </v-col>
              </v-row>
            </div>
            <div v-if="source && !source_job && !generating_success">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :value="source.cost.journey.toLocaleString()"
                    outlined
                    dense
                    readonly
                    background-color="grey lighten-2"
                    :label="$t('gw.licences.credits')"
                  >
                    <template #prepend-inner>
                      <v-icon color="#ffd700">mdi-circle-multiple</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :value="currentCreditsCount"
                    outlined
                    dense
                    readonly
                    :label="$t('gw.licences.credits_count')"
                    :background-color="
                      (!licences.ghostwriter.licence.can_overload &&
                        source.cost.journey >
                          licences.ghostwriter.credits_count) ||
                      (licences.ghostwriter.licence.can_overload &&
                        source.cost.journey >
                          licences.ghostwriter.credits_count +
                            licences.ghostwriter.licence.credits_overload)
                        ? 'error'
                        : 'success'
                    "
                  >
                    <template #prepend-inner>
                      <v-icon color="#ffd700">mdi-circle-multiple</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-expand-transition>
              <div v-if="!!source_job && !generating_success">
                <v-alert
                  color="secondary"
                  prominent
                  icon="mdi-information"
                  dark
                  text
                >
                  <p>
                    {{ $t("gw.journey.generate.alert") }}
                  </p>
                  <v-progress-linear
                    color="secondary"
                    height="12"
                    indeterminate
                  />
                </v-alert>
              </div>
            </v-expand-transition>

            <div v-if="generating_success">
              <v-alert color="info" prominent icon="mdi-check-circle" dark text>
                {{ $t("gw.journey.generate.alert_success") }}
              </v-alert>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn text small @click="closeDialog()">
              {{ $t("general.close") }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="
                !source && !generating_success && [0, 1].includes(inputMode)
              "
              text
              color="success"
              small
              :loading="loading"
              :disabled="
                loading ||
                !valid ||
                !title ||
                ([1].includes(inputMode) && !selectedFile) ||
                showCharLengthWarning
              "
              @click="addSource()"
            >
              {{ $t("gw.journey.generate.add_content") }}
            </v-btn>
            <v-btn
              v-if="source && !generating_success"
              text
              color="success"
              small
              :loading="loading"
              :disabled="
                loading ||
                !valid ||
                (!licences.ghostwriter.licence.can_overload &&
                  source &&
                  source.cost.journey > licences.ghostwriter.credits_count) ||
                (licences.ghostwriter.licence.can_overload &&
                  source &&
                  source.cost.journey >
                    licences.ghostwriter.credits_count +
                      licences.ghostwriter.licence.credits_overload) ||
                !title ||
                is_generating ||
                (inputMode === 1 &&
                  ((source && !source.body) ||
                    (source && source.body.length === 0))) ||
                ([3, 4].includes(inputMode) &&
                  (!selected_source ||
                    (source && !source.body) ||
                    (source && source.body.length === 0))) ||
                this.showCharLengthWarning
              "
              @click="generateJourney()"
            >
              {{ $t("gw.journey.generate.title") }}
            </v-btn>
            <v-btn
              v-if="generating_success && inputText"
              :to="{ name: 'GwJourneys' }"
              text
              small
              >{{ $t("gw.show_journeys") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";
import PresentationUploadDialog from "@/components/files/PresentationUploadDialog";
import FileSourceDialog from "@/components/files/FileSourceDialog";
import CountryFlag from "vue-country-flag";

export default {
  name: "GwGenerateJourneyDialog",
  components: {
    LanguageChooserInline,
    PresentationUploadDialog,
    FileSourceDialog,
    CountryFlag,
  },
  props: {
    inputText: {
      type: String,
      required: false,
    },
    inputLanguage: {
      type: String,
      required: false,
    },
    inputTitle: {
      type: String,
      required: false,
    },
    hidePrevSources: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      valid: false,
      lang: "de-DE",
      title: "",
      loading: false,
      inputMode: 0, // 0: txt, 1: pdf, 2: video, 3: ppt
      txt: "",
      maxCharLength: 400000,
      is_generating: false,
      initial_fetch_done: false,
      generating_success: false,
      source: null,
      selected_source: null,
      selectedFile: null,
      rules: {
        file: [
          (value) =>
            !value ||
            value.size < 500000000 ||
            this.$t("resources.media.validation", { size: "500" }),
        ],
        title: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
        text: [(v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint")],
      },
      progress: null,
    };
  },
  computed: {
    ...mapGetters("auth", { licences: "get_licences" }),
    ...mapGetters("ghostwriter", {
      sources: "get_sources",
      gw_source: "get_source",
      source_job: "get_job",
    }),
    currentCreditsCount() {
      if (!this.licences || !this.licences.ghostwriter) return "-";
      var credits = this.licences.ghostwriter.credits_count.toLocaleString();
      var pool_string = "";
      if (this.licences.ghostwriter.licence.can_overload) {
        pool_string = ` (${this.$t(
          "gw.licences.credit_pool"
        )}: ${this.licences.ghostwriter.licence.credits_overload.toLocaleString()})`;
      }
      return credits + pool_string;
    },
    filteredSources() {
      var sources = [...this.sources];
      var current_mimetype = this.inputMode === 0 ? "txt" : "pdf";

      return sources
        .filter(
          (s) =>
            !s.is_deleted && !s.is_archived && s.mimetype === current_mimetype
        )
        .sort((a, b) => b.id - a.id);
    },
    filteredFileSources() {
      var sources = [...this.sources];
      return sources
        .filter((s) => !s.is_deleted && !s.is_archived && s.mimetype === "file")
        .sort((a, b) => b.id - a.id);
    },
    showPPTFeature() {
      return process.env.VUE_APP_GHOSTWRITER_JOURNEY_PPT === "true";
    },
    showCharLengthWarning() {
      if (!this.txt) return false;
      return this.txt.length > this.maxCharLength;
    },
  },
  watch: {
    showDialog(v) {
      if (v) {
        this.lang = this.$i18n.locale;
        if (this.inputText) this.txt = this.inputText;
        if (this.inputLanguage) this.lang = this.inputLanguage;
        if (this.inputTitle) this.title = this.inputTitle.substring(0, 64); // TODO: remove hardcoded value once longer title is supported
        this.fetchSources();
      } else {
        this.reset();
      }
    },
    inputMode() {
      this.source = null;
      this.title = "";
      this.txt = "";
      this.lang = this.$i18n.locale;
      this.selected_source = null;
    },
    selected_source: {
      handler: async function (v) {
        if (!v) {
          this.source = null;
          this.txt = "";
          this.title = "";
        }
        if (v) {
          await this.fetchSources();
          await this.fetchSource(v.id);
        }
      },
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "add_source",
      "fetch_sources",
      "fetch_source",
      "fetch_job",
      "fetch_ghostwriter_job",
      "fetch_gw_journeys",
      "generate_gw_journey",
      "fetch_sources_generating",
      "patch_source",
    ]),
    ...mapActions("auth", ["init_auth"]),
    ...mapMutations("ghostwriter", ["set_job"]),
    closeDialog() {
      this.showDialog = false;
      if (this.generating_success) {
        this.fetchJourneys();
      }
      this.reset();
    },
    async fetchJourneys() {
      await this.fetch_gw_journeys();
      await this.fetch_sources_generating();
    },
    async fetchSource(id) {
      if (!id) return false;
      this.loading = true;
      var res = await this.fetch_source({ id: id });
      this.loading = false;
      if (!res || res._status !== 200) {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
        this.selected_source = null;
        return false;
      }
      this.source = { ...this.gw_source };
      this.title = this.gw_source.title;
      this.txt = this.gw_source.body;
      this.lang = this.gw_source.lang;
    },
    async addSource() {
      this.loading = true;
      var payload = null;
      if (this.inputMode === 0) {
        payload = {
          txt: this.txt,
          title: this.title,
        };
      }
      if (this.inputMode === 1) {
        let inputs = new FormData();
        inputs.append("file", this.$refs.gwdocument.initialValue);
        inputs.append("title", this.title);
        inputs.append("lang", this.lang);
        payload = inputs;
      }

      if ([0].includes(this.inputMode)) {
        payload.lang = this.lang;
      }

      var res = await this.add_source({
        payload: payload,
        type: this.inputMode === 0 ? "txt" : "pdf", // update after other input options are available
        config:
          this.inputMode === 1
            ? { headers: { "Content-Type": "multipart/form-data" } }
            : null,
      });
      this.loading = false;
      if (
        res &&
        res._status === 200 &&
        res.source.body &&
        res.source.body.length > 0
      ) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
        this.selected_source = res.source;
        if (this.inputMode === 1) {
          this.selectedFile = null;
        }
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
    reset() {
      clearInterval(this.progress);
      this.set_job(null);
      this.txt = "";
      this.title = "";
      this.lang = this.$i18n.locale;
      this.source = null;
      this.inputMode = 0;
      this.selected_source = null;
      this.is_generating = false;
      this.generating_success = false;
      this.initial_fetch_done = false;
    },
    openDialog(text) {
      if (text) this.txt = text;
      this.showDialog = true;
    },
    changeLanguage(val) {
      this.lang = val;
    },
    async fetchSources() {
      await this.fetch_sources();
    },
    async generateJourney() {
      if (!this.source) {
        console.error("no source");
        return false;
      }
      this.is_generating = true;
      var res = await this.generate_gw_journey({
        id: this.source.id,
      });
      if (res && res._status === 200) {
        this.checkJob(res.source.job.id);
      } else {
        this.is_generating = false;
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
    async refreshData(id) {
      console.warn("refresh generate journey dialog");
      this.inputMode === 3
        ? await this.fetch_ghostwriter_job({ id: id })
        : await this.fetch_job({
            id: id,
          });

      this.checkJob(id);
    },
    async checkJob(id) {
      if (!this.initial_fetch_done) {
        this.inputMode === 3
          ? await this.fetch_ghostwriter_job({ id: id })
          : await this.fetch_job({
              id: id,
            });
      }
      this.initial_fetch_done = true;
      if (
        !this.source ||
        !id ||
        !this.showDialog ||
        (this.source_job && this.source_job.status === "SUCCESS") ||
        (this.source_job && this.source_job.status === "FAILURE") ||
        !this.showDialog
      ) {
        this.cancelJob();
        return false;
      }
      if (!this.source || !id) return false;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (this.progress) return false;
      this.progress = setInterval(
        function () {
          this.refreshData(this.source_job.id);
        }.bind(this),
        5000
      );
    },
    async cancelJob() {
      if (this.source_job && this.source_job.status !== "FAILURE") {
        this.set_job(null);
        await this.init_auth({ id: this.auth.id });
        this.is_generating = false;
        this.generating_success = true;
        this.initial_fetch_done = false;
      }
      clearInterval(this.progress);
    },
    setTitle(e) {
      this.title = e;
    },
    setSource(v) {
      this.selected_source = v;
    },
    flagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      if (lang === "uk-UK") return "ua"; // ukrainian flag fallback
      if (lang === "ko-KO") return "kr"; // korean flag fallback
      return lang.slice(-2);
    },
    async changeSourceTitle() {
      // endpoint not working atm
      this.loading = true;
      var res = await this.patch_source({
        payload: {
          pages: this.source.pages,
          title: this.title,
          lang: this.source.lang,
          body: this.source.body,
        },
        id: this.source.id,
        config: null,
      });
      this.loading = false;

      if (res._status === 200) {
        this.selected_source = res.source;
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
    duplicateSource() {
      var tmp = {
        title: this.title,
        txt: this.txt,
        lang: this.lang,
      };
      this.inputMode = 0;

      // wait for watcher to reset and then re-assign values
      this.$nextTick(() => {
        this.title = `${this.$i18n.t("ng.copy")}: ${tmp.title}`;
        this.txt = tmp.txt;
        this.lang = tmp.lang;
      });
    },
  },
};
</script>
