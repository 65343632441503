<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-card outlined>
            <v-form v-model="valid3">
              <v-card-title class="secondary--text">{{
                $t("ng.journey.change_image_title")
              }}</v-card-title>
              <v-card-subtitle>{{
                $t("ng.journey.change_image_subtitle")
              }}</v-card-subtitle>
              <v-card-text>
                <ImageUpload
                  :image-hash="input.image_token"
                  :disabled="
                    !['superadmin', 'admin', 'editor'].includes(role) ||
                    isEditing
                  "
                  :show-notification="false"
                  @image-changed="imageChange"
                />
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <v-card outlined>
            <v-form
              v-model="valid"
              :disabled="!['superadmin', 'admin', 'editor'].includes(role)"
            >
              <v-card-title class="secondary--text">
                {{ $t("ng.journey.edit_journey") }}
              </v-card-title>
              <v-card-subtitle>{{
                $t("ng.journey.edit_journey_subtitle")
              }}</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <InputTextfield
                      v-model="input.name"
                      :label="$t('resources.journeys.propNames.name')"
                      :rules="rules.name"
                      disabled
                      counter="64"
                      translatable
                      @is-editing="setEditing"
                      @save="updateName"
                    >
                      <template
                        #actions="{
                          editing,
                          startEditing,
                          cancelEditing,
                          saveInput,
                          inputChanged,
                        }"
                      >
                        <div v-if="!editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  :disabled="
                                    isEditing ||
                                    loading ||
                                    !['editor', 'admin', 'superadmin'].includes(
                                      role
                                    )
                                  "
                                  @click="startEditing()"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.edit") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <JourneyTranslationDialog
                                  prop-name="name"
                                  :journey="journey"
                                  :disabled="!input.name || loading"
                                >
                                  <template #button="{ onClick }">
                                    <v-btn
                                      icon
                                      :disabled="
                                        !input.name ||
                                        input.name.length === 0 ||
                                        isEditing ||
                                        loading ||
                                        ![
                                          'editor',
                                          'admin',
                                          'superadmin',
                                        ].includes(role)
                                      "
                                      @click="onClick"
                                    >
                                      <v-icon small> mdi-translate </v-icon>
                                    </v-btn>
                                  </template>
                                </JourneyTranslationDialog>
                              </div>
                            </template>
                            <span>{{ $t("general.translations") }}</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="cancelEditing()"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.cancel") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="success"
                                  :disabled="
                                    !inputChanged ||
                                    !['editor', 'admin', 'superadmin'].includes(
                                      role
                                    )
                                  "
                                  @click="saveInput()"
                                >
                                  <v-icon small> mdi-check </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.save") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </InputTextfield>
                  </v-col>
                  <v-col cols="12">
                    <InputTextarea
                      v-model="input.description"
                      :label="$t('resources.journeys.propNames.description')"
                      :rules="rules.description"
                      disabled
                      counter="200"
                      translatable
                      @is-editing="setEditing"
                      @save="updateDescription"
                    >
                      <template
                        #actions="{
                          editing,
                          startEditing,
                          cancelEditing,
                          saveInput,
                          inputChanged,
                        }"
                      >
                        <div v-if="!editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  :disabled="
                                    isEditing ||
                                    loading ||
                                    !['editor', 'admin', 'superadmin'].includes(
                                      role
                                    )
                                  "
                                  @click="startEditing()"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.edit") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <JourneyTranslationDialog
                                  prop-name="description"
                                  :journey="journey"
                                  :disabled="!input.description || loading"
                                >
                                  <template #button="{ onClick }">
                                    <v-btn
                                      icon
                                      :disabled="
                                        !input.description ||
                                        input.description.length === 0 ||
                                        isEditing ||
                                        loading ||
                                        ![
                                          'editor',
                                          'admin',
                                          'superadmin',
                                        ].includes(role)
                                      "
                                      @click="onClick"
                                    >
                                      <v-icon small> mdi-translate </v-icon>
                                    </v-btn>
                                  </template>
                                </JourneyTranslationDialog>
                              </div>
                            </template>
                            <span>{{ $t("general.translations") }}</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="cancelEditing()"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.cancel") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="success"
                                  :disabled="
                                    !inputChanged ||
                                    !['editor', 'admin', 'superadmin'].includes(
                                      role
                                    )
                                  "
                                  @click="saveInput()"
                                >
                                  <v-icon small> mdi-check </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.save") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </InputTextarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-form v-model="valid3">
        <v-card outlined>
          <v-card-title class="secondary--text">{{
            $t("ng.further_actions_descr")
          }}</v-card-title>
          <v-card-subtitle>{{
            $t("ng.further_actions_descr_2")
          }}</v-card-subtitle>
          <v-card-text>
            <v-alert v-if="journey.status !== 'unpublished'" text color="info">
              {{ $t("resources.journeys.editWarning") }}
            </v-alert>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="input.attempts"
                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
                  dense
                  outlined
                  background-color="white"
                  :label="$t('resources.journeys.propNames.attempts')"
                  :disabled="
                    journey.status !== 'unpublished' ||
                    !['editor', 'admin', 'superadmin'].includes(role)
                  "
                  :menu-props="{ bottom: true, offsetY: true }"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="input.coins"
                  :rules="rules.coins"
                  required
                  :label="$t('resources.journeys.propNames.coins')"
                  dense
                  outlined
                  background-color="white"
                  type="number"
                  :disabled="
                    journey.status !== 'unpublished' ||
                    !['editor', 'admin', 'superadmin'].includes(role)
                  "
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-dialog
                  ref="startAtPicker"
                  v-model="startAtPicker"
                  :return-value.sync="input.start_at"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="input.start_at"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      background-color="white"
                      :label="$t('resources.journeys.propNames.start_at')"
                      :disabled="
                        journey.status !== 'unpublished' ||
                        !['editor', 'admin', 'superadmin'].includes(role)
                      "
                      v-on="on"
                    >
                      <template #append>
                        <v-btn
                          icon
                          small
                          :disabled="
                            journey.status !== 'unpublished' ||
                            !['editor', 'admin', 'superadmin'].includes(role)
                          "
                          @click="removeStartDate()"
                        >
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.start_at"
                    scrollable
                    :min="minDate"
                  >
                    <v-spacer />
                    <v-btn text color="primary" @click="startAtPicker = false">
                      {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      :disabled="
                        !['editor', 'admin', 'superadmin'].includes(role)
                      "
                      @click="$refs.startAtPicker.save(input.start_at)"
                    >
                      {{ $t("general.apply") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6">
                <v-dialog
                  ref="endAtPicker"
                  v-model="endAtPicker"
                  :return-value.sync="input.end_at"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="input.end_at"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      background-color="white"
                      :label="$t('resources.journeys.propNames.end_at')"
                      :disabled="
                        !input.start_at ||
                        journey.status !== 'unpublished' ||
                        !['editor', 'admin', 'superadmin'].includes(role)
                      "
                      v-on="on"
                    >
                      <template #append>
                        <v-btn
                          icon
                          small
                          :disabled="
                            journey.status !== 'unpublished' ||
                            !['editor', 'admin', 'superadmin'].includes(role)
                          "
                          @click="removeEndDate()"
                        >
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.end_at"
                    scrollable
                    :min="endAtMin"
                  >
                    <v-spacer />
                    <v-btn text color="primary" @click="endAtPicker = false">
                      {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      :disabled="
                        !['editor', 'admin', 'superadmin'].includes(role)
                      "
                      @click="$refs.endAtPicker.save(input.end_at)"
                    >
                      {{ $t("general.apply") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              small
              color="error"
              :disabled="!inputChanged || loading"
              @click="resetForm"
            >
              {{ $t("general.reset") }}
            </v-btn>
            <v-btn
              text
              small
              color="primary"
              :disabled="!inputChanged || !valid3 || loading"
              @click="save"
            >
              {{ $t("general.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageUpload from "@/components/global/ImageUpload";
import JourneyTranslationDialog from "@/components/journeys/dialogs/JourneyTranslationDialog";

export default {
  name: "JourneySettingsForm",
  components: {
    ImageUpload,
    JourneyTranslationDialog,
  },
  data() {
    return {
      input: null,
      valid: true,
      valid2: true,
      valid3: true,
      startAtPicker: false,
      endAtPicker: false,
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
            }),
          (v) =>
            (v && v.length <= 64) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
              max: 64,
            }),
        ],
        description: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
            }),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
              max: 200,
            }),
        ],
        coins: [
          (v) =>
            (v >= 0 && v <= 10000) ||
            this.$t("forms.rules.inBetween", {
              inputFieldName: this.$t("resources.journeys.propNames.coins"),
              min: 0,
              max: 10000,
            }),
        ],
      },
      minDate: new Date(new Date().valueOf() + 1000 * 3600 * 24)
        .toISOString()
        .substr(0, 10),
      editModes: {
        name: false,
        description: false,
      },
      loading: false,
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      language: "get_selected_language",
    }),
    inputChanged() {
      let changed = false;
      if (this.input.attempts !== this.journey.attempts) changed = true;
      if (this.input.coins != this.journey.coins) changed = true;
      if (this.input.start_at !== this.journey.start_at) changed = true;
      if (this.input.end_at !== this.journey.end_at) changed = true;
      return changed;
    },
    imageChanged() {
      return this.input.image !== this.journey.image;
    },
    endAtMin() {
      if (this.input.start_at)
        return new Date(
          new Date(this.input.start_at).valueOf() + 1000 * 3600 * 24
        )
          .toISOString()
          .substr(0, 10);
      return this.minDate;
    },
  },
  beforeMount() {
    this.input = { ...this.journey };
    this.input.coins = this.journey.coins; // fallback because init value wasnt recogniced in form validation
  },
  watch: {
    journey: {
      handler: function () {
        this.input = { ...this.journey };
      },
    },
  },
  methods: {
    ...mapActions("journeys", ["patch_journey", "patch_translation"]),
    resetForm() {
      this.input = { ...this.journey };
    },
    async save() {
      this.loading = true;
      let properties = [
        { name: "image", value: this.input.image ? this.input.image : "" },
        { name: "attempts", value: this.input.attempts },
        { name: "coins", value: Number(this.input.coins) },
        {
          name: "start_at",
          value: this.input.start_at ? this.input.start_at : null,
        },
        { name: "end_at", value: this.input.end_at ? this.input.end_at : null },
      ];
      let payload = {
        journey_id: this.journey.id,
        properties: properties,
        language: this.language,
      };
      let res = await this.patch_journey({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async saveImage() {
      this.loading = true;
      let properties = [
        { name: "image", value: this.input.image ? this.input.image : "" },
      ];
      let payload = {
        journey_id: this.journey.id,
        properties: properties,
        language: this.language,
      };
      let res = await this.patch_journey({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    removeStartDate() {
      this.input.start_at = "";
      this.input.end_at = "";
    },
    removeEndDate() {
      this.input.end_at = "";
    },
    imageChange(image) {
      this.input.image_token = image.hash;
      this.input.image = image.filename;
      this.saveImage();
    },
    async updateName(value, translate) {
      let properties = [{ name: "name", value: value }];
      this.loading = true;
      let payload = {
        id: this.journey.id,
        language: this.language,
        override: translate,
        properties: properties,
      };
      let res = await this.patch_translation({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async updateDescription(value, translate) {
      let properties = [{ name: "description", value: value }];
      this.loading = true;
      let payload = {
        id: this.journey.id,
        language: this.language,
        override: translate,
        properties: properties,
      };
      let res = await this.patch_translation({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    setEditing(val) {
      this.isEditing = val;
    },
  },
};
</script>

<style>
.input-group__input * {
  color: rgba(0, 0, 0, 0.1) !important;
}
</style>
