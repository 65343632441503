<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.categories.plural')"
      icon="mdi-shape"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'CategoryOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="!substructures || !category" />

    <section v-if="substructures && category">
      <SectionHeader
        :title="`${$t('resources.questions.sectionHeaders.overview.title')}`"
        :subtitle="$t('resources.journeys.sectionHeaders.generalInfo.subtitle')"
      >
        <template #action>
          <div class="d-flex align-center">
            <CategoryRemover :category="category" />
            <CategoryActivator :category="category" :disabled="edited" />
          </div>
        </template>
      </SectionHeader>

      <v-form v-model="valid" :disabled="loading">
        <v-container>
          <v-alert
            v-if="!category.can.activate"
            type="info"
            text
            dense
            colored-border
            border="left"
          >
            <span class="caption">{{
              $t("resources.categories.needs_10_questions")
            }}</span>
          </v-alert>

          <v-row>
            <v-col cols="12">
              <CategoryImageChanger
                :image-input="input.image_url"
                patch-image
                :disabled="edited"
                :category-id="id"
                large
              />
            </v-col>
            <v-col cols="12">
              <InputTextfield
                v-model="input.name"
                :rules="rules.name"
                required
                dense
                :label="$t('resources.categories.name')"
                background-color="white"
                listen-to-input
              >
                <template #actions>
                  <CategoryTranslationDialog
                    prop-name="name"
                    :category="category"
                  >
                    <template #button="{ onClick }">
                      <v-btn icon @click="onClick">
                        <v-icon small> mdi-translate </v-icon>
                      </v-btn>
                    </template>
                  </CategoryTranslationDialog>
                </template>
              </InputTextfield>
            </v-col>
            <v-col cols="12">
              <InputTextarea
                v-model="input.description"
                :rules="rules.description"
                required
                dense
                height="120"
                :label="$t('resources.journeys.propNames.description')"
                background-color="white"
                listen-to-input
              >
                <template #actions>
                  <CategoryTranslationDialog
                    prop-name="description"
                    :category="category"
                  >
                    <template #button="{ onClick }">
                      <v-btn icon @click="onClick">
                        <v-icon small> mdi-translate </v-icon>
                      </v-btn>
                    </template>
                  </CategoryTranslationDialog>
                </template>
              </InputTextarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="input.substructure_ids"
                :items="sortedSubstructures"
                item-text="name"
                item-value="id"
                attach
                chips
                small-chips
                deletable-chips
                :messages="
                  !['superadmin', 'admin'].includes(role)
                    ? $t('general.errors.admin_only')
                    : ''
                "
                :disabled="!['superadmin', 'admin'].includes(role)"
                multiple
                outlined
                :hint="$t('ng.category_substructure_hint')"
                persistent-hint
                :label="$t('resources.categories.substructure_ids')"
                background-color="white"
              >
                <template #item="{ item }">
                  {{ item.structure.name }} / {{ item.name }}
                </template>
                <template #selection="{ item }">
                  <v-chip small label color="primary">
                    {{ substructureById(item.id).structure.name }} /
                    {{ item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <CategorygroupSelector
                :input="category.groups"
                @changed-groups="setGroups"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex">
              <v-spacer />
              <v-btn
                color="error"
                text
                small
                :disabled="!edited"
                @click="resetForm"
              >
                {{ $t("general.clear") }}
              </v-btn>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="!valid || !edited"
                @click="saveForm"
              >
                {{ $t("general.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </section>

    <section v-if="category && substructures" class="mt-4">
      <SectionHeader :title="`${$t('resources.categories.stats')}`" />
      <v-container>
        <v-row>
          <v-col cols="6" md="3">
            <v-card
              class="d-flex flex-column align-center justify-center"
              color="white"
              outlined
              style="border: 1px solid rgba(0, 0, 0, 0.13) !important"
              height="100"
              @click="showQuestions()"
            >
              <v-icon small style="position: absolute; top: 8px; right: 8px"
                >mdi-open-in-new</v-icon
              >
              <h3 class="info--text">
                {{ category.stats.questions.count.toLocaleString() }}
              </h3>
              <span class="caption text-uppercase font-weight-bold">{{
                $t("resources.categories.active_questions")
              }}</span>
            </v-card>
          </v-col>

          <v-col cols="6" md="3">
            <v-card
              class="d-flex flex-column align-center justify-center"
              color="white"
              outlined
              style="border: 1px solid rgba(0, 0, 0, 0.13) !important"
              height="100"
              @click="showNuggets()"
            >
              <v-icon small style="position: absolute; top: 8px; right: 8px"
                >mdi-open-in-new</v-icon
              >
              <h3 class="info--text">
                {{ category.stats.nuggets.count.toLocaleString() }}
              </h3>
              <span class="caption text-uppercase font-weight-bold">{{
                $t("resources.categories.active_nuggets")
              }}</span>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <v-card
              class="d-flex flex-column align-center justify-center"
              color="white"
              outlined
              style="border: 1px solid rgba(0, 0, 0, 0.13) !important"
              height="100"
            >
              <h3 class="info--text">
                {{ category.stats.questions.played.toLocaleString() }}
              </h3>
              <span class="caption text-uppercase font-weight-bold">{{
                $t("resources.categories.statistics_list.questions_played")
              }}</span>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card
              class="d-flex flex-column align-center justify-center"
              color="white"
              outlined
              style="border: 1px solid rgba(0, 0, 0, 0.13) !important"
              height="100"
            >
              <h3 class="info--text">
                {{ category.stats.questions.correct.toLocaleString() }} ({{
                  category.stats.questions.percent.toLocaleString()
                }}%)
              </h3>
              <span class="caption text-uppercase font-weight-bold">{{
                $t("resources.categories.statistics_list.questions_correct")
              }}</span>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card
              class="d-flex flex-column align-center justify-center"
              color="white"
              outlined
              style="border: 1px solid rgba(0, 0, 0, 0.13) !important"
              height="100"
            >
              <h3 class="info--text">
                {{ category.stats.rounds.played.toLocaleString() }}
              </h3>
              <span class="caption text-uppercase font-weight-bold">{{
                $t("resources.categories.statistics_list.rounds_count")
              }}</span>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card
              class="d-flex flex-column align-center justify-center"
              color="white"
              outlined
              style="border: 1px solid rgba(0, 0, 0, 0.13) !important"
              height="100"
            >
              <h3 class="info--text">
                {{ category.stats.rounds.perfect.toLocaleString() }}
              </h3>
              <span class="caption text-uppercase font-weight-bold">{{
                $t("resources.categories.statistics_list.rounds_perfect")
              }}</span>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import CategoryActivator from "@/components/categories/CategoryActivator";
import CategoryRemover from "@/components/categories/CategoryRemover";
import CategoryImageChanger from "@/components/categories/CategoryImageChanger";
import CategorygroupSelector from "@/components/categories/CategorygroupSelector";
import CategoryTranslationDialog from "@/components/categories/CategoryTranslationDialog";

export default {
  name: "CategoryDetail",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
    CategoryActivator,
    CategoryRemover,
    CategoryImageChanger,
    CategorygroupSelector,
    CategoryTranslationDialog,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      input: {
        name: "",
        description: "",
        //image_url: '',
        substructure_ids: [],
        group_ids: [],
      },
      valid: false,
      loading: false,
      rules: {
        name: [
          (v) =>
            (!!v && v.length >= 3) ||
            this.$t("general.errors.not_enough_chars"),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("resources.categories.errors.name_too_long"),
        ],
        description: [
          //v => (!!v && v.length >= 3) || this.$t('general.errors.not_enough_chars'),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("resources.categories.errors.name_too_long"), // TODO max length?
        ],
      },
    };
  },
  computed: {
    ...mapGetters("categories", { category: "get_category" }),
    ...mapGetters("structures", { substructures: "get_substructures" }),
    edited() {
      if (!this.category || !this.input) {
        return false;
      }
      let category_group_ids = [];
      this.category.groups.forEach((group) =>
        category_group_ids.push(group.id)
      );
      return (
        this.input.name != this.category.name ||
        this.input.description != this.category.description ||
        this.input.substructure_ids.join(",") !=
          this.category.substructure_ids.join(",") ||
        this.input.group_ids.join(",") != category_group_ids.join(",")
      );
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.categories.plural"),
          disabled: false,
          to: { name: "CategoryOverview" },
        },
      ];
    },
    sortedSubstructures() {
      var substructures = [...this.substructures];
      return substructures.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    category() {
      this.resetForm();
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
  },
  destroyed() {
    this.set_category(null);
    this.set_structures(null);
  },
  methods: {
    ...mapActions("categories", [
      "fetch_category",
      "patch_category",
      "delete_category",
    ]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("categories", ["set_category"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      await this.fetch_structures();
      await this.fetch_category({
        id: this.id,
      });
      this.resetForm();
    },
    resetForm() {
      let image = this.category.img_url.split("/");
      let group_ids = [];
      this.category.groups.forEach((group) => group_ids.push(group.id));
      this.input = {
        name: this.category.name,
        description: this.category.description,
        substructure_ids: this.category.substructure_ids,
        group_ids: group_ids,
        image_url: image[image.length - 1],
      };
    },
    async saveForm() {
      this.loading = true;
      let properties = [
        { name: "name", value: this.input.name },
        { name: "description", value: this.input.description },
        { name: "substructure_ids", value: this.input.substructure_ids },
        { name: "group_ids", value: this.input.group_ids },
      ];

      let payload = {
        properties: properties,
        language: this.$i18n.locale,
        override: true,
      };

      let res = await this.patch_category({
        id: this.id,
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.currentImage = this.selectedImage;
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: `${this.$t("resources.categories.singular")} ${this.$t(
            "general.updateSuccess"
          )}`,
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: `${this.$t("resources.categories.singular")} ${this.$t(
            "general.updateError"
          )}`,
        });
      }
    },
    substructureById(id) {
      if (!this.substructures) return [];

      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    setGroups(val) {
      this.input.group_ids = val;
    },
    showQuestions() {
      var filter = {
        categories: [this.id],
        is_active: [true],
        search: "",
        question_type: [],
        reported: false,
        status: [],
        page: 1,
      };
      localStorage.setItem("fil_que", JSON.stringify(filter));
      this.$router.push({ name: "QuestionOverview" });
    },
    showNuggets() {
      var filter = {
        categories: [this.id],
        type: [],
        source: [],
        is_active: [true],
        search: "",
        page: 1,
      };
      localStorage.setItem("fil_nug", JSON.stringify(filter));
      this.$router.push({ name: "NuggetOverview" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
