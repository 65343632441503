var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-form',{model:{value:(_vm.valid3),callback:function ($$v) {_vm.valid3=$$v},expression:"valid3"}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t("ng.journey.change_image_title")))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t("ng.journey.change_image_subtitle")))]),_c('v-card-text',[_c('ImageUpload',{attrs:{"image-hash":_vm.input.image_token,"disabled":!['superadmin', 'admin', 'editor'].includes(_vm.role) ||
                  _vm.isEditing,"show-notification":false},on:{"image-changed":_vm.imageChange}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-form',{attrs:{"disabled":!['superadmin', 'admin', 'editor'].includes(_vm.role)},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("ng.journey.edit_journey"))+" ")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t("ng.journey.edit_journey_subtitle")))]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('InputTextfield',{attrs:{"label":_vm.$t('resources.journeys.propNames.name'),"rules":_vm.rules.name,"disabled":"","counter":"64","translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateName},scopedSlots:_vm._u([{key:"actions",fn:function({
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                      }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing ||
                                  _vm.loading ||
                                  !['editor', 'admin', 'superadmin'].includes(
                                    _vm.role
                                  )},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('JourneyTranslationDialog',{attrs:{"prop-name":"name","journey":_vm.journey,"disabled":!_vm.input.name || _vm.loading},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.name ||
                                      _vm.input.name.length === 0 ||
                                      _vm.isEditing ||
                                      _vm.loading ||
                                      ![
                                        'editor',
                                        'admin',
                                        'superadmin',
                                      ].includes(_vm.role)},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged ||
                                  !['editor', 'admin', 'superadmin'].includes(
                                    _vm.role
                                  )},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}]),model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('InputTextarea',{attrs:{"label":_vm.$t('resources.journeys.propNames.description'),"rules":_vm.rules.description,"disabled":"","counter":"200","translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateDescription},scopedSlots:_vm._u([{key:"actions",fn:function({
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                      }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing ||
                                  _vm.loading ||
                                  !['editor', 'admin', 'superadmin'].includes(
                                    _vm.role
                                  )},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('JourneyTranslationDialog',{attrs:{"prop-name":"description","journey":_vm.journey,"disabled":!_vm.input.description || _vm.loading},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.description ||
                                      _vm.input.description.length === 0 ||
                                      _vm.isEditing ||
                                      _vm.loading ||
                                      ![
                                        'editor',
                                        'admin',
                                        'superadmin',
                                      ].includes(_vm.role)},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged ||
                                  !['editor', 'admin', 'superadmin'].includes(
                                    _vm.role
                                  )},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}]),model:{value:(_vm.input.description),callback:function ($$v) {_vm.$set(_vm.input, "description", $$v)},expression:"input.description"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-container',[_c('v-form',{model:{value:(_vm.valid3),callback:function ($$v) {_vm.valid3=$$v},expression:"valid3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t("ng.further_actions_descr")))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t("ng.further_actions_descr_2")))]),_c('v-card-text',[(_vm.journey.status !== 'unpublished')?_c('v-alert',{attrs:{"text":"","color":"info"}},[_vm._v(" "+_vm._s(_vm.$t("resources.journeys.editWarning"))+" ")]):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":[1, 2, 3, 4, 5, 6, 7, 8, 9],"dense":"","outlined":"","background-color":"white","label":_vm.$t('resources.journeys.propNames.attempts'),"disabled":_vm.journey.status !== 'unpublished' ||
                  !['editor', 'admin', 'superadmin'].includes(_vm.role),"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.input.attempts),callback:function ($$v) {_vm.$set(_vm.input, "attempts", $$v)},expression:"input.attempts"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.rules.coins,"required":"","label":_vm.$t('resources.journeys.propNames.coins'),"dense":"","outlined":"","background-color":"white","type":"number","disabled":_vm.journey.status !== 'unpublished' ||
                  !['editor', 'admin', 'superadmin'].includes(_vm.role)},model:{value:(_vm.input.coins),callback:function ($$v) {_vm.$set(_vm.input, "coins", $$v)},expression:"input.coins"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"startAtPicker",attrs:{"return-value":_vm.input.start_at,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.input, "start_at", $event)},"update:return-value":function($event){return _vm.$set(_vm.input, "start_at", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","dense":"","outlined":"","background-color":"white","label":_vm.$t('resources.journeys.propNames.start_at'),"disabled":_vm.journey.status !== 'unpublished' ||
                      !['editor', 'admin', 'superadmin'].includes(_vm.role)},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.journey.status !== 'unpublished' ||
                          !['editor', 'admin', 'superadmin'].includes(_vm.role)},on:{"click":function($event){return _vm.removeStartDate()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]},proxy:true}],null,true),model:{value:(_vm.input.start_at),callback:function ($$v) {_vm.$set(_vm.input, "start_at", $$v)},expression:"input.start_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startAtPicker),callback:function ($$v) {_vm.startAtPicker=$$v},expression:"startAtPicker"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.minDate},model:{value:(_vm.input.start_at),callback:function ($$v) {_vm.$set(_vm.input, "start_at", $$v)},expression:"input.start_at"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startAtPicker = false}}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!['editor', 'admin', 'superadmin'].includes(_vm.role)},on:{"click":function($event){return _vm.$refs.startAtPicker.save(_vm.input.start_at)}}},[_vm._v(" "+_vm._s(_vm.$t("general.apply"))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"endAtPicker",attrs:{"return-value":_vm.input.end_at,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.input, "end_at", $event)},"update:return-value":function($event){return _vm.$set(_vm.input, "end_at", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","dense":"","outlined":"","background-color":"white","label":_vm.$t('resources.journeys.propNames.end_at'),"disabled":!_vm.input.start_at ||
                      _vm.journey.status !== 'unpublished' ||
                      !['editor', 'admin', 'superadmin'].includes(_vm.role)},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.journey.status !== 'unpublished' ||
                          !['editor', 'admin', 'superadmin'].includes(_vm.role)},on:{"click":function($event){return _vm.removeEndDate()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]},proxy:true}],null,true),model:{value:(_vm.input.end_at),callback:function ($$v) {_vm.$set(_vm.input, "end_at", $$v)},expression:"input.end_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endAtPicker),callback:function ($$v) {_vm.endAtPicker=$$v},expression:"endAtPicker"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.endAtMin},model:{value:(_vm.input.end_at),callback:function ($$v) {_vm.$set(_vm.input, "end_at", $$v)},expression:"input.end_at"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endAtPicker = false}}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!['editor', 'admin', 'superadmin'].includes(_vm.role)},on:{"click":function($event){return _vm.$refs.endAtPicker.save(_vm.input.end_at)}}},[_vm._v(" "+_vm._s(_vm.$t("general.apply"))+" ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","color":"error","disabled":!_vm.inputChanged || _vm.loading},on:{"click":_vm.resetForm}},[_vm._v(" "+_vm._s(_vm.$t("general.reset"))+" ")]),_c('v-btn',{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.inputChanged || !_vm.valid3 || _vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }