<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />
    <v-dialog
      v-model="dialog"
      width="800"
      scrollable
      persistent
      :disabled="loading"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card color="grey lighten-3">
        <v-card-title class="overline secondary--text">{{
          $t("gw.journey.category.title")
        }}</v-card-title>
        <v-card-subtitle>{{
          $t("gw.journey.category.subtitle")
        }}</v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="pt-4">
              <CategorySelector
                :input-value="selected_category"
                :label="$t('gw.journey.category.select_category')"
                @change="setCategory"
              />
              <!-- <v-autocomplete
                v-model="selected_category"
                :items="categories"
                item-text="name"
                item-value="id"
                outlined
                background-color="white"
                :label="$t('gw.journey.category.select_category')"
                dense
              /> -->
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn small text :disabled="loading" @click="closeDialog()">{{
            $t("general.close")
          }}</v-btn>
          <v-spacer />
          <v-btn
            small
            text
            color="success"
            :disabled="!categoryHasChanged"
            :loading="loading"
            @click="updateCategory()"
            >{{ $t("general.assign") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategorySelector from "@/components/global/CategorySelector";

export default {
  name: "GwJourneyCategoryChanger",
  components: {
    CategorySelector,
  },
  props: {
    journey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      selected_category: null,
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_categories" }),
    categoryHasChanged() {
      return this.selected_category !== this.journey.category_id;
    },
  },
  watch: {
    dialog(v) {
      if (v) this.selected_category = this.journey.category_id;
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["update_gw_journey"]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async updateCategory() {
      this.loading = true;
      var payload = {
        id: this.journey.id,
        category_id: this.selected_category,
      };
      var res = await this.update_gw_journey({ payload: payload });
      this.loading = false;
      if (res && res._status === 200) {
        this.closeDialog();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
    setCategory(v) {
      this.selected_category = v;
    },
  },
};
</script>
