import api from "@/services/api";
import ghostwriterapi from "@/services/ghostwriterapi";
import { GwSession } from "@/models/ghostwriter/GwSession";
import { GwTranscription } from "@/models/ghostwriter/GwTranscription";
import { GwCreditPool } from "@/models/ghostwriter/GwCreditPool";
import { GwLicence } from "@/models/ghostwriter/GwLicence";

export const fetch_sessions = async ({ commit, dispatch }, config = {}) => {
  try {
    let res = await api.get("admin/gw/sessions", config);
    if (res._status === 200) {
      let sessions = res.sessions
        .filter((s) => s.type === 1)
        .map((s) => new GwSession(s))
        .sort((a, b) => b.created_at - a.created_at);
      commit(
        "set_sessions",
        sessions.filter((s) => !s.is_deleted)
      );
      await dispatch("auth/init_auth", config, { root: true });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
export const fetch_bulk_sessions = async (
  { commit, dispatch },
  config = {}
) => {
  try {
    let res = await api.get("admin/gw/sessions", config);
    if (res._status === 200) {
      let sessions = res.sessions
        .filter((s) => s.type === 2)
        .map((s) => new GwSession(s))
        .sort((a, b) => b.created_at - a.created_at);
      commit(
        "set_bulk_sessions",
        sessions.filter((s) => !s.is_deleted)
      );
      await dispatch("auth/init_auth", config, { root: true });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_session = async (
  { commit, dispatch },
  { id, config = {} }
) => {
  try {
    let res = await api.get(`admin/gw/sessions/${id}`, config);
    if (res._status === 200) {
      commit("set_session", new GwSession(res.session));
      dispatch("auth/init_auth", config, { root: true });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
export const fetch_bulk_session = async (
  { commit, dispatch },
  { id, config = {} }
) => {
  try {
    let res = await api.get(`admin/gw/sessions/${id}`, config);
    if (res._status === 200) {
      commit("set_bulk_session", new GwSession(res.session));
      await dispatch("auth/init_auth", config, { root: true });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_session = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/gw/sessions", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_session = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.patch(
      `admin/gw/sessions/${payload.session_id}`,
      payload,
      config
    );
    if (res._status === 200) {
      if (payload.session_type === 1)
        await dispatch("fetch_session", { id: payload.session_id });
      if (payload.session_type === 2)
        await dispatch("fetch_bulk_session", { id: payload.session_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const delete_session = async (_, { id, config = {} }) => {
  try {
    let res = await api.delete(`admin/gw/sessions/${id}`, config);
    if (res._status === 200) {
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_part = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/gw/parts", payload, config);
    if (res._status === 200) {
      await dispatch("fetch_session", { id: payload.session_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const delete_part = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.delete("admin/gw/parts/" + payload.part_id, config);
    if (res._status === 200) {
      await dispatch("fetch_session", { id: payload.session_id });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const generate_content = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/gw/parts/${payload.part_id}/generate`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_session", { id: payload.session_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const generate_content_by_type = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/gw/parts/${payload.part_id}/generate/${payload.type}`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_session", { id: payload.session_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_content = async ({ dispatch }, { payload, config = {} }) => {
  // type: questions || nuggets
  try {
    let res = await api.patch(
      `admin/gw/${payload.type}/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      if (payload.session_type === "default")
        await dispatch("fetch_session", { id: payload.session_id });
      if (payload.session_type === "bulk")
        await dispatch("fetch_bulk_session", { id: payload.session_id });

      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const accept_content = async (
  { dispatch },
  { payload, config = {} }
) => {
  // type: questions || nuggets
  try {
    let res = await api.post(
      `admin/gw/${payload.type}/${payload.id}/accept`,
      payload,
      config
    );
    if (res._status === 200) {
      setTimeout(async () => {
        await dispatch("fetch_bulk_session", { id: payload.session_id });
      }, 1000);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const refresh_content = async (
  { dispatch },
  { payload, config = {} }
) => {
  // type: questions || nuggets
  try {
    let res = await api.post(
      `admin/gw/${payload.type}/${payload.id}/generate`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_session", { id: payload.session_id });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const delete_content = async (
  { dispatch },
  { payload, config = {} }
) => {
  // type: questions || nuggets
  try {
    let res = await api.delete(
      `admin/gw/${payload.type}/${payload.id}`,
      config
    );
    if (res._status === 200) {
      if (payload.session_type === "default")
        await dispatch("fetch_session", { id: payload.session_id });
      if (payload.session_type === "bulk")
        await dispatch("fetch_bulk_session", { id: payload.session_id });

      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const import_session = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(`admin/gw/sessions/${payload.id}/submit`, config);
    if (res._status === 200) {
      await dispatch("fetch_session", { id: payload.id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_licences = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/gw/licences", config);
    if (res._status === 200) {
      let licences = res.licences
        .map((s) => new GwLicence(s))
        .sort((a, b) => a.id - b.id);
      commit("set_licences", licences);
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const upload_document = async (_, { payload, config }) => {
  try {
    let res = await api.post("admin/gw/upload/pdf", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const generate_bulk_content = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/gw/sessions/${payload.id}/generate`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_bulk_session", { id: payload.id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_gw_media_upload_token = async (_, config = {}) => {
  try {
    let res = await api.get("admin/gw/upload/token", config);
    if (res.success) {
      return res.token;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const create_transcription = async (_, { payload, type, config }) => {
  try {
    let res = await ghostwriterapi.post(
      `ai/${type}/transcription`,
      payload,
      config
    );
    return res;
    /* if (res.success) {
      return res.media;
    } else {
      return null;
    } */
  } catch (err) {
    return null;
  }
};

export const fetch_gw_upload_progress = async (_, { config = {} }) => {
  let res = await ghostwriterapi.get("progress", config);
  if (res) return res;
  return null;
};

export const fetch_transcriptions = async (
  { commit, dispatch },
  { config = {} }
) => {
  try {
    let res = await ghostwriterapi.get("ai/transcriptions/", config);
    if (res) {
      var transcriptions = res.map((t) => new GwTranscription(t));
      commit("set_transcriptions", transcriptions);
      await dispatch("auth/init_auth", config, { root: true });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_transcription = async (
  { commit, dispatch },
  { id, config = {} }
) => {
  try {
    let res = await ghostwriterapi.get(`ai/transcriptions/${id}`, config);
    if (res) {
      commit("set_transcription", new GwTranscription(res));
      await dispatch("auth/init_auth", config, { root: true });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const generate_transcription = async (_, { payload, config = {} }) => {
  try {
    let res = await ghostwriterapi.patch(
      `ai/transcriptions/generate`,
      payload,
      config
    );
    return res;
  } catch (err) {
    return null;
  }
};

export const delete_transcription = async (_, { id, config = {} }) => {
  try {
    let res = await ghostwriterapi.delete(`ai/transcriptions/${id}`, config);
    return res;
  } catch (err) {
    return null;
  }
};

export const fetch_credit_pool = async ({ commit }, config = {}) => {
  try {
    let res = await api.get(`admin/gw/credits`, config);
    if (res && res._status === 200) {
      commit(
        "set_credit_pool",
        res.credits ? new GwCreditPool(res.credits) : null
      );
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const add_credit_pool = async ({ commit }, { payload, config = {} }) => {
  try {
    let res = await api.post(`admin/gw/credits`, payload, config);
    if (res && res._status === 200) {
      commit(
        "set_credit_pool",
        res.credits ? new GwCreditPool(res.credits) : null
      );
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const update_credit_pool = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(`admin/gw/credits/${payload.id}`, payload, config);
    if (res && res._status === 200) {
      commit(
        "set_credit_pool",
        res.credits ? new GwCreditPool(res.credits) : null
      );
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const delete_credit_pool = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.delete(`admin/gw/credits/${id}`, config);
    if (res && res._status === 200) {
      commit("set_credit_pool", null);
    }
    return res;
  } catch (err) {
    return null;
  }
};

// Sources / GW Journeys
export const fetch_sources = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/cnt/sources/", config);
    if (res._status === 200) {
      commit("set_sources", res.sources);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};
export const fetch_sources_generating = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/cnt/sources/generating", config);
    if (res._status === 200) {
      commit("set_sources_generating", res.sources);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};
export const fetch_gw_journeys = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/cnt/journeys/", config);
    if (res._status === 200) {
      commit("set_gw_journeys", res.journeys);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const fetch_source = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/cnt/sources/${id}`, config);
    if (res._status === 200) {
      commit("set_source", res.source);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const fetch_gw_journey = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/cnt/journeys/${id}`, config);
    if (res._status === 200) {
      commit("set_gw_journey", res.journey);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const add_source = async (_, { payload, type, config = {} }) => {
  try {
    // TODO: update endpoint after different types are available
    let res = await api.post(
      `admin/cnt/sources/upload/${type}`,
      payload,
      config
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const patch_source = async (
  { dispatch },
  { payload, id, config = {} }
) => {
  try {
    // TODO: update endpoint after different types are available
    let res = await api.patch(`admin/cnt/sources/file/${id}`, payload, config);
    if (res && res._status === 200) {
      dispatch("fetch_source", { id: res.source.id });
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};
export const put_source = async (_, { payload, id, config = {} }) => {
  try {
    // TODO: update endpoint after different types are available
    let res = await api.put(`admin/cnt/sources/${id}`, payload, config);
    /* if (res && res._status === 200) {
      dispatch("fetch_source", { id: res.source.id });
    } */
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const fetch_job = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/cnt/jobs/backend/${id}`, config);
    if (res._status === 200) {
      commit("set_job", res.job);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};
export const fetch_fileserver_job = async (_, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/cnt/jobs/fileserver/${id}`, config);
    /* if (res._status === 200) {
      commit("set_job", res.job);
    } */
    return res;
  } catch (err) {
    console.error(err);
  }
};
export const fetch_ghostwriter_job = async (
  { commit },
  { id, config = {} }
) => {
  try {
    let res = await api.get(`admin/cnt/jobs/ghostwriter/${id}`, config);
    if (res._status === 200) {
      commit("set_job", res.job);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const structure_source = async (_, { id, payload, config = {} }) => {
  try {
    // TODO: update endpoint after different types are available
    let res = await api.post(
      `admin/cnt/sources/${id}/generate/structure`,
      payload,
      config
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const generate_gw_journey = async (
  { dispatch },
  { id, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/cnt/sources/${id}/generate/journey`,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_sources_generating");
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const import_gw_journey = async ({ dispatch }, { id, config = {} }) => {
  try {
    let res = await api.post(`admin/cnt/journeys/${id}/submit`, config);
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: id });
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};
export const accept_gw_journey_content = async (
  { dispatch },
  { id, payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/cnt/journeys/${id}/accept`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: id });
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const update_gw_journey = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      `admin/cnt/journeys/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: payload.id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const update_gw_bundle = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      `admin/cnt/journeys/bundles/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: payload.journey_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const update_gw_journey_nugget = async (
  { dispatch },
  { payload, id, journey_id, config = {} }
) => {
  try {
    let res = await api.put(
      `admin/cnt/journeys/nuggets/${id}`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: journey_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const reject_gw_journey_nugget = async (
  { dispatch },
  { action, id, journey_id, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/cnt/journeys/nuggets/${id}/${action}`,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: journey_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const update_gw_journey_question = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      `admin/cnt/journeys/questions/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: payload.journey_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const reject_gw_journey_question = async (
  { dispatch },
  { action, id, journey_id, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/cnt/journeys/questions/${id}/${action}`,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: journey_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const update_gw_journey_question_answer = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      `admin/cnt/journeys/answers/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_gw_journey", { id: payload.journey_id });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};
