<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.content.title')"
      icon="mdi-content-paste"
    />

    <section>
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col cols="12" sm="6">
            <TextWidget
              color="grey lighten-3"
              :title="$t('resources.categories.plural')"
              route-name="CategoryOverview"
              :route-description="$t('general.show')"
              :subtitle="$t('views.content.category_teaser')"
              link-type-text
              link-color="secondary"
              icon="mdi-shape"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <TextWidget
              color="grey lighten-3"
              :title="$t('resources.questions.plural')"
              route-name="QuestionOverview"
              :route-description="$t('general.show')"
              :subtitle="$t('views.content.question_teaser')"
              link-type-text
              link-color="secondary"
              icon="mdi-comment-question"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <TextWidget
              color="grey lighten-3"
              :title="$t('resources.nuggets.plural')"
              route-name="NuggetOverview"
              :route-description="$t('general.show')"
              :subtitle="$t('views.content.nugget_teaser')"
              link-type-text
              link-color="secondary"
              icon="mdi-school"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import TextWidget from "@/components/widgets/TextWidget";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "ContentView",
  components: {
    ViewHeader,
    TextWidget,
    SectionHeader,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
  },
};
</script>

<style></style>
